import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'

const ModalComponent = ({ children, closeModal, isOpen }) => {
  const nodeRef = useRef(null)
  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      nodeRef={nodeRef}
      in={isOpen}
      timeout={500}
      classNames="modal"
    >
      <div className="fixed inset-0 z-[51] grid h-screen w-full place-content-center">
        <div
          className="absolute inset-0 w-full bg-black opacity-25"
          onClick={closeModal}
        ></div>

        <div
          ref={nodeRef}
          className="z-[60] flex w-full max-w-5xl items-center justify-center overflow-y-auto overflow-x-hidden rounded-lg bg-white shadow-lg outline-none focus:outline-none"
        >
          {children}
        </div>
      </div>
    </CSSTransition>
  )
}

const Modal = ({ isOpen, children, closeModal }) => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const modalContainer = document.createElement('div')
      document.body.appendChild(modalContainer)

      return () => {
        document.body.removeChild(modalContainer)
      }
    }
  }, [isOpen, closeModal])

  return isOpen
    ? createPortal(
        <ModalComponent closeModal={closeModal} isOpen={isOpen}>
          {children}
        </ModalComponent>,
        document.body
      )
    : null
}

export default Modal
