import React, { createContext, useEffect, useReducer, useCallback } from 'react'
import { navigate } from 'gatsby'
import { Request } from '../helpers/Request'
import { initialState } from '../global/user/initialState'
import { reducer } from '../global/user/reducer'

export const UserDataContext = createContext({ ...initialState })

function getSessionValue() {
  let result = localStorage.getItem('SessionIdMF')
  return result
}

function getSessionToken() {
  let result = localStorage.getItem('SessionToken')
  result = result.substr(1, 30)
  return result
}

function closeSessionSecureStore(value) {
  localStorage.setItem('SessionIdMF', value)
}

export const UserDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setAction = (type, payload) => {
    dispatch({ type, payload })
  }

  const userData = {
    ...state,
    setLogin: useCallback(user => setAction('login', user), []),
    setMarket: useCallback(market => setAction('market', market), []),
    setFilter: useCallback(filter => setAction('filter', filter), []),
    setSearch: useCallback(search => setAction('search', search), []),
    setDrawerCart: useCallback(drawer => setAction('drawerCart', drawer), []),
    setModal: useCallback(modal => setAction('modal', { modalOpen: modal }), []),
    setModalAuthType: useCallback(type => setAction('modalAuthType', type), []),
    setAuthenticated: useCallback(auth => setAction('isAuthenticated', auth), []),
    setMenuProfileStep: useCallback(menu => setAction('menuProfileStep', menu), []),
    setQueryOrder: useCallback(id => setAction('idQueryOrder', id), []),
    logout: useCallback(() => LogoutUser(), [])
  }

  const LogoutUser = () => {
    navigate('/logout')

    closeSessionSecureStore('')
    dispatch({ type: 'logout' })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const { setAuthenticated, setLogin, setMarket } = userData
    try {
      const id_user = getSessionValue()
      const token = getSessionToken()
      if (id_user === '') {
        return setAuthenticated(false)
      }

      const info_user = await Request('info-user', { id_user: id_user })
      if (info_user?.status !== 'success') {
        return setAuthenticated(false)
      }

      const cookie = await Request('put-user-session', { id_cookie: id_user.id_cookie })

      const user = info_user.datos_usuario
      setLogin({
        id_user: id_user,
        names: user.nombres,
        email: user.email,
        phone: user.telefono,
        code: user.id_codigo_pais,
        user_type: user.user_type,
        token: token
      })
      setMarket({
        id_market: user.id_market,
        market_name: user.market_name,
        direction: user.direccion,
        lat: user.lat,
        lon: user.lon
      })
    } catch (error) {
      setAuthenticated(false)
      console.log(error)
    }
  }

  return <UserDataContext.Provider value={userData}>{children}</UserDataContext.Provider>
}
