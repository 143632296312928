import { initialState } from './initialState'

export const buyReducer = (state, action) => {
  switch (action.type) {
    case 'product': {
      return {
        ...state,
        product: { ...state.product, ...action.payload }
      }
    }

    case 'productsContext': {
      return {
        ...state,
        productsContext: [...action.payload]
      }
    }

    case 'productsToCart':
      return {
        ...state,
        products: [...action.payload]
      }

    case 'products': {
      const some = state.products.some(product => product.id === action.payload.id)
      if (!some) {
        const newProduct = {
          ...action.payload,
          dedication: {
            mensaje: '',
            frase: ''
          }
        }
        return {
          ...state,
          products: [...state.products, newProduct]
        }
      }
      return state
    }

    case 'amountPlus': {
      const newProducts = state.products.map(product => {
        if (product.id === action.payload) {
          const newCantidad = Math.min(product.cantidad + 1, 50)
          return {
            ...product,
            cantidad: newCantidad
          }
        }
        return product
      })
      return {
        ...state,
        products: newProducts
      }
    }

    case 'amountMinus': {
      const newProducts = state.products.map(product => {
        if (product.id === action.payload) {
          const newCantidad = Math.max(product.cantidad - 1, 1)
          return {
            ...product,
            cantidad: newCantidad
          }
        }
        return product
      })
      return {
        ...state,
        products: newProducts
      }
    }
    case 'deleteProduct':
      return {
        ...state,
        products: state.products.filter(product => product.id !== action.payload)
      }
    case 'additionals':
      return {
        ...state,
        additionals: { ...state.additionals, ...action.payload }
      }
    case 'emitter':
      return {
        ...state,
        emitter: { ...state.emitter, ...action.payload }
      }
    case 'receiver':
      return {
        ...state,
        receiver: { ...state.receiver, ...action.payload }
      }
    case 'dedication':
      return {
        ...state,
        dedication: { ...state.dedication, ...action.payload }
      }
    case 'deliveryTime':
      return {
        ...state,
        deliveryTime: { ...state.deliveryTime, ...action.payload }
      }
    case 'payment':
      return {
        ...state,
        payment: { ...state.payment, ...action.payload }
      }
    case 'reset':
      return {
        ...initialState
      }
    default:
      return state
  }
}
