import React, { createContext, useReducer } from 'react'
import { initialState } from '../global/shop/initialState'
import { shopReducer } from '../global/shop/reducer'

export const ShopDataContext = createContext({})

export const ShopDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(shopReducer, initialState)

  const setShop = shop => {
    dispatch({ type: 'shop', payload: shop })
  }

  const setProduct = product => {
    dispatch({ type: 'product', payload: product })
  }

  const setDelivery = delivery => {
    dispatch({ type: 'delivery', payload: delivery })
  }

  const setHistory = history => {
    dispatch({ type: 'history', payload: history })
  }

  const setResetShop = () => {
    dispatch({ type: 'reset' })
  }

  return (
    <ShopDataContext.Provider
      value={{
        ...state,
        setShop,
        setProduct,
        setDelivery,
        setHistory,
        setResetShop
      }}
    >
      {children}
    </ShopDataContext.Provider>
  )
}
