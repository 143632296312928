import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useCallback
} from 'react'
import { initialState } from '../global/buy/initialState'
import { buyReducer } from '../global/buy/reducer'
import { UserDataContext } from './userContext'
import { Request } from '@helpers/Request'

export const BuyDataContext = createContext({ ...initialState })

const setSecureStorage = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value)
    localStorage.setItem(key, jsonValue)
  } catch (e) {
    console.log(e)
  }
}

const getSecureStorage = key => {
  try {
    const jsonValue = localStorage.getItem(key)
    return jsonValue != null ? JSON.parse(jsonValue) : null
  } catch (e) {
    console.log(e)
  }
}

export const BuyDataProvider = ({ children }) => {
  const useData = useContext(UserDataContext)
  const { id_user } = useData.user
  const [state, dispatch] = useReducer(buyReducer, initialState)
  const { productsContext } = state
  const { products, product } = state
  const { precioEscogido, cupon, singleProduct } = state.payment
  const { monto_descuento } = cupon
  const { adicionalesEscogidos, adicionalesTotalPrecio } = state.additionals

  const setAction = (type, payload) => {
    dispatch({ type, payload })
  }

  const setProducts = useCallback(async product => {
    const _products = [...state.products, product].map(product => ({
      id: product.id,
      id_categoria: product.id_categoria_app,
      cantidad: product.cantidad
    }))
    await setSecureStorage('products', _products)
    setAction('products', product)
  })

  const setDeleteProduct = useCallback(async id => {
    const asyncStorageProducts = await getSecureStorage('products')
    const newAsyncStorageProducts = asyncStorageProducts.filter(
      product => product.id !== id
    )
    await setSecureStorage('products', newAsyncStorageProducts)
    setAction('deleteProduct', id)
  })

  const setAmountMinus = useCallback(async id => {
    const asyncStorageProducts = await getSecureStorage('products')
    const newAsyncStorageProducts = asyncStorageProducts.map(product => {
      if (product.id === id) {
        const newCantidad = Math.max(product.cantidad - 1, 1)
        return {
          ...product,
          cantidad: newCantidad
        }
      }
      return product
    })
    await setSecureStorage('products', newAsyncStorageProducts)
    setAction('amountMinus', id)
  })

  const setAmountPlus = useCallback(async id => {
    const asyncStorageProducts = await getSecureStorage('products')
    const newAsyncStorageProducts = asyncStorageProducts.map(product => {
      if (product.id === id) {
        const newCantidad = Math.min(product.cantidad + 1, 50)

        return {
          ...product,
          cantidad: newCantidad
        }
      }
      return product
    })
    await setSecureStorage('products', newAsyncStorageProducts)
    setAction('amountPlus', id)
  })

  const setResetBuy = useCallback(async () => {
    if (!singleProduct) {
      setSecureStorage('products', [])
    }
    setAction('reset')
  })

  const buyData = {
    ...state,
    setProduct: useCallback(product => setAction('product', product), []),
    setProductsToCart: useCallback(products => setAction('productsToCart', products), []),
    setProducts,
    setProductToContext: useCallback(
      products => setAction('productsContext', products),
      []
    ),
    setDeleteProduct,
    setAmountPlus,
    setAmountMinus,
    setResetBuy,
    setPlusCuanty: useCallback(id => setAction('plusCuanty', id), []),
    setMinusCuanty: useCallback(id => setAction('minusCuanty', id), []),
    setAdditionals: useCallback(additionals => setAction('additionals', additionals), []),
    setEmitter: useCallback(emitter => setAction('emitter', emitter), []),
    setReceiver: useCallback(receiver => setAction('receiver', receiver), []),
    setDedication: useCallback(dedication => setAction('dedication', dedication), []),
    setDelivery: useCallback(deliveryTime => setAction('deliveryTime', deliveryTime), []),
    setPayment: useCallback(payment => setAction('payment', payment), [])
  }

  // efecto para actualizar el precio total de la compra y comisiones de tarjeta
  useEffect(() => {
    const subtotal = singleProduct
      ? Number(product.precio * product.cantidad)
      : products.reduce(
          (acc, product) => acc + Number(product.precio * product.cantidad),
          0
        )
    const sumaTotal =
      subtotal +
      Number(precioEscogido) +
      Number(adicionalesTotalPrecio) -
      Number(monto_descuento)
    const comision = 0
    const igv = 0
    const totalTarjeta = sumaTotal + comision + igv
    const { setPayment } = buyData
    setPayment({
      total_precio: sumaTotal,
      precio_envio: precioEscogido,
      subtotal: subtotal,
      comision: comision,
      igv: igv,
      total_impuestos: Math.ceil(comision + igv),
      total_precio_tarjeta: Math.ceil(totalTarjeta)
    })
  }, [precioEscogido, adicionalesTotalPrecio, products, singleProduct, product])

  // efecto para actualizar el precio total de los adicionales
  useEffect(() => {
    let total = 0
    for (let adicional of adicionalesEscogidos) {
      total += adicional.precioTotal
    }
    const { setAdditionals } = buyData
    setAdditionals({ adicionalesTotalPrecio: total })
  }, [adicionalesEscogidos])

  // efecto para actualizar addAdditional a true o false
  useEffect(() => {
    const { setAdditionals } = buyData
    if (adicionalesEscogidos.length === 0) {
      setAdditionals({
        addAdditional: false
      })
    } else {
      setAdditionals({
        addAdditional: true
      })
    }
  }, [adicionalesEscogidos])

  function buscarProductoPorIdCategoriaYId(idCategoria, idProducto) {
    const categoriaEncontrada = productsContext.find(
      categoria => categoria.id_categoria === idCategoria
    )
    if (categoriaEncontrada) {
      const productoEncontrado = categoriaEncontrada.data.find(
        producto => producto.id === idProducto
      )
      return productoEncontrado
    }
    return null
  }

  const asyncProductsToCart = async () => {
    const { setProductsToCart } = buyData
    const nuevosProductos = []
    if (state.productsContext.length === 0) {
      return
    }

    const asyncStorageProducts = await getSecureStorage('products')
    if (asyncStorageProducts !== null && asyncStorageProducts.length > 0) {
      asyncStorageProducts.forEach(product => {
        const productoEncontrado = buscarProductoPorIdCategoriaYId(
          product.id_categoria,
          product.id
        )
        if (productoEncontrado !== null && productoEncontrado !== undefined) {
          nuevosProductos.push({
            ...productoEncontrado,
            cantidad: product.cantidad,
            dedication: {
              mensaje: '',
              frase: ''
            }
          })
        }
      })
      setProductsToCart(nuevosProductos)
    }
  }

  function buscarProductoVan(productos) {
    const productVan = productos.find(producto => Boolean(producto.is_van) === true)
    let productPrimary
    if (productVan) {
      // Si hay algún producto con is_van true, lo copiamos en product_primary
      productPrimary = { ...productVan }
    } else if (productos.length > 0) {
      // Si no hay producto con is_van true, tomamos el primer elemento del array
      // y lo copiamos en product_primary
      productPrimary = { ...productos[0] }
    } else {
      // En caso de que no haya ningún producto en el array, asignamos un valor por defecto
      return
    }

    return {
      product_primary: productPrimary,
      products: productos
    }
  }

  useEffect(() => {
    if (id_user !== null && id_user !== undefined) {
      fetchCoupons()
    }
  }, [id_user])

  const fetchCoupons = async () => {
    const { setPayment } = buyData
    try {
      const response = await Request('get-usuario-cupones', {
        id_usuario: +id_user
      })
      if (response.status === 'success' && response.data.length > 0) {
        return setPayment({
          cupon: {
            id: response.data[0].id,
            monto_descuento: response.data[0].monto_descuento,
            codigo_cupon: response.data[0].codigo_cupon
          }
        })
      }
      return setPayment({
        cupon: {
          id: '',
          monto_descuento: 0,
          codigo_cupon: ''
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    asyncProductsToCart()
  }, [productsContext])

  useEffect(() => {
    if (!singleProduct) {
      const result = buscarProductoVan(products)
      if (result) {
        const { setProduct } = buyData
        setProduct({
          ...result.product_primary,
          lat: result.product_primary.latitud,
          lon: result.product_primary.longitud,
          is_van: Boolean(result.product_primary.is_van)
        })
      }
    }
  }, [products, singleProduct])

  return <BuyDataContext.Provider value={buyData}>{children}</BuyDataContext.Provider>
}
