exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adm-index-js": () => import("./../../../src/pages/adm/index.js" /* webpackChunkName: "component---src-pages-adm-index-js" */),
  "component---src-pages-agrega-al-pedido-index-js": () => import("./../../../src/pages/agrega-al-pedido/index.js" /* webpackChunkName: "component---src-pages-agrega-al-pedido-index-js" */),
  "component---src-pages-blog-[url]-js": () => import("./../../../src/pages/blog/[url].js" /* webpackChunkName: "component---src-pages-blog-[url]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-buscar-[texto]-js": () => import("./../../../src/pages/buscar/[texto].js" /* webpackChunkName: "component---src-pages-buscar-[texto]-js" */),
  "component---src-pages-calificacion-[id]-[hash]-js": () => import("./../../../src/pages/calificacion/[id]/[hash].js" /* webpackChunkName: "component---src-pages-calificacion-[id]-[hash]-js" */),
  "component---src-pages-calificacion-receptor-[id]-js": () => import("./../../../src/pages/calificacion/receptor/[id].js" /* webpackChunkName: "component---src-pages-calificacion-receptor-[id]-js" */),
  "component---src-pages-cambiar-ubicacion-js": () => import("./../../../src/pages/cambiar-ubicacion.js" /* webpackChunkName: "component---src-pages-cambiar-ubicacion-js" */),
  "component---src-pages-carrito-index-js": () => import("./../../../src/pages/carrito/index.js" /* webpackChunkName: "component---src-pages-carrito-index-js" */),
  "component---src-pages-categoria-[id]-js": () => import("./../../../src/pages/categoria/[id].js" /* webpackChunkName: "component---src-pages-categoria-[id]-js" */),
  "component---src-pages-detalle-[id]-index-js": () => import("./../../../src/pages/detalle/[id]/index.js" /* webpackChunkName: "component---src-pages-detalle-[id]-index-js" */),
  "component---src-pages-detalle-[id]-pago-confirmacion-js": () => import("./../../../src/pages/detalle/[id]/pago/confirmacion.js" /* webpackChunkName: "component---src-pages-detalle-[id]-pago-confirmacion-js" */),
  "component---src-pages-detalle-[id]-pago-index-js": () => import("./../../../src/pages/detalle/[id]/pago/index.js" /* webpackChunkName: "component---src-pages-detalle-[id]-pago-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-perfil-js": () => import("./../../../src/pages/perfil.js" /* webpackChunkName: "component---src-pages-perfil-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-proceso-compra-cupon-js": () => import("./../../../src/pages/proceso-compra/cupon.js" /* webpackChunkName: "component---src-pages-proceso-compra-cupon-js" */),
  "component---src-pages-proceso-compra-dedicatoria-js": () => import("./../../../src/pages/proceso-compra/dedicatoria.js" /* webpackChunkName: "component---src-pages-proceso-compra-dedicatoria-js" */),
  "component---src-pages-proceso-compra-emisor-js": () => import("./../../../src/pages/proceso-compra/emisor.js" /* webpackChunkName: "component---src-pages-proceso-compra-emisor-js" */),
  "component---src-pages-proceso-compra-horarios-js": () => import("./../../../src/pages/proceso-compra/horarios.js" /* webpackChunkName: "component---src-pages-proceso-compra-horarios-js" */),
  "component---src-pages-proceso-compra-index-js": () => import("./../../../src/pages/proceso-compra/index.js" /* webpackChunkName: "component---src-pages-proceso-compra-index-js" */),
  "component---src-pages-proceso-compra-mapa-js": () => import("./../../../src/pages/proceso-compra/mapa.js" /* webpackChunkName: "component---src-pages-proceso-compra-mapa-js" */),
  "component---src-pages-proceso-compra-pago-js": () => import("./../../../src/pages/proceso-compra/pago.js" /* webpackChunkName: "component---src-pages-proceso-compra-pago-js" */),
  "component---src-pages-proceso-compra-receptor-js": () => import("./../../../src/pages/proceso-compra/receptor.js" /* webpackChunkName: "component---src-pages-proceso-compra-receptor-js" */),
  "component---src-pages-promociones-js": () => import("./../../../src/pages/promociones.js" /* webpackChunkName: "component---src-pages-promociones-js" */),
  "component---src-pages-reclamaciones-js": () => import("./../../../src/pages/reclamaciones.js" /* webpackChunkName: "component---src-pages-reclamaciones-js" */),
  "component---src-pages-registrate-js": () => import("./../../../src/pages/registrate.js" /* webpackChunkName: "component---src-pages-registrate-js" */),
  "component---src-pages-terminos-js": () => import("./../../../src/pages/terminos.js" /* webpackChunkName: "component---src-pages-terminos-js" */),
  "component---src-pages-tienda-[id]-categoria-id-categoria-index-js": () => import("./../../../src/pages/tienda/[id]/categoria/[id_categoria]/index.js" /* webpackChunkName: "component---src-pages-tienda-[id]-categoria-id-categoria-index-js" */),
  "component---src-pages-tienda-[id]-index-js": () => import("./../../../src/pages/tienda/[id]/index.js" /* webpackChunkName: "component---src-pages-tienda-[id]-index-js" */),
  "component---src-pages-verifica-sms-js": () => import("./../../../src/pages/verifica-sms.js" /* webpackChunkName: "component---src-pages-verifica-sms-js" */)
}

