import React from 'react'
import { InfinitySpin } from 'react-loader-spinner'

function Loading({ width: width = 200, color = '#A3B932' }) {
  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 z-[62] flex h-full w-full items-center justify-center bg-black bg-opacity-20 ">
      <InfinitySpin width={width} color={color} />
    </div>
  )
}

export default Loading
