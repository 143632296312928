export async function Request(endpoint, data) {
  try {
    const response = await fetch(process.env.api_gateway + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.x_api_key
      },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    return result
  } catch (error) {
    console.log(error)
  }
}
