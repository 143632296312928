import React from 'react'
import toast from 'react-hot-toast'

export const ToastMessage = ({ title = 'Hola', user, image, subtitle, children }) => {
  toast.custom(t => {
    return (
      <div
        className={`${
          t.visible
            ? 'animate-fade-left animate-ease-in-out'
            : 'animate-fade ease-out animate-reverse'
        } pointer-events-auto flex h-full w-full max-w-sm items-center justify-center rounded-lg bg-white px-2 py-0.5 shadow-lg ring-1 ring-black ring-opacity-5 drop-shadow-sm`}
      >
        <div className="h-full flex-1 gap-1">
          <div className="flex items-center justify-center gap-4">
            {image && (
              <div className="h-full flex-shrink-0 justify-end">
                <img className="h-full w-20" src={image} alt={title} title={title} />
              </div>
            )}
            <span className="flex-1 text-sm font-medium text-gray-700">
              {title} <span className="text-green-700">{user}</span>, {subtitle}
              {children && <span className="text-green-700">{children}</span>}
            </span>
          </div>
        </div>
      </div>
    )
  })
}
