import React, { useContext, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { phoneCodes, PhoneCodesQuery } from '@constant/query'
import { UserDataContext } from '@context/userContext'
import { Request } from '@helpers/Request'
import Loading from '@components/loader/Loading'
import { navigate } from 'gatsby'
import { ToastMessage } from '@components/notification/ToastMessage'

function SaveLocalstorage(type, value) {
  localStorage.setItem(type, JSON.stringify(value))
}

const Login = () => {
  const userData = useContext(UserDataContext)
  const { setModal, setLogin } = userData
  const { modalOpen } = userData.modal
  const [selectedOption, setSelectedOption] = useState(1)
  const [placeholder, setPlaceholder] = useState('Ingrese tu correo')
  const [numbers, setNumbers] = useState(9)
  const [phoneCode, setPhoneCode] = useState('+51')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [dataVerification, setDataVerification] = useState(null)
  const [code, setCode] = useState('')

  const { data } = useQuery({
    queryKey: [phoneCodes],
    queryFn: () => PhoneCodesQuery()
  })

  const LoginAccount = async () => {
    setIsLoading(true)

    const response = await Request('login-email', {
      user_email: email
    });

    if (response.status && response.status === 'success') {
      setIsLoading(false)
      setStep(1)
    }
  }

  const Confirm = async () => {
    setIsLoading(true)
    const response = await Request('verify-user-login-email', {
      user_email: email,
      code_verification: code,
    })
    if (response.status && response.status === 'success') {
      if (response.data.existe == 'true') {
        setIsLoading(false)
        SaveLocalstorage('SessionIdMF', response.data.id_user)
        SaveLocalstorage('SessionType', response.data.user_type)
        SaveLocalstorage('SessionToken', response.data.token)

        setLogin({
          id_user: response.data.id_user,
          names: response.data.names,
          email: response.data.email,
          phone: response.data.phone,
          code: response.data.code,
          user_type: response.data.user_type,
          token: response.data.token
        })
        setModal(!modalOpen)
        navigate('/')
        ToastMessage({
          image: 'https://cdn.mercadodeflores.pe/hola-que-lindo-verte.jpg',
          title: 'Hola',
          user: response.data.names,
          children: 'que lindo verte por aquí \u2764\uFE0F'
        })

      } else {
        setIsLoading(false)
        SaveLocalstorage('userEmail', email)
        SaveLocalstorage('codeVerification', code)
        setModal(!modalOpen)
        navigate('/registrate')
      }
    } else {
      setIsLoading(false)
      window.alert(response.msg)
    }
  }

  return step === 0 ? (
    <div className="relative flex w-full flex-col gap-0 py-10 md:flex-row md:gap-7 md:py-0">
      <div className="flex h-full flex-col items-center justify-center overflow-hidden md:w-1/2 md:bg-[#FFFFFF]">
        <img
          src='https://cdn.mercadodeflores.pe/ingresa-o-registrate.jpg'
          alt="Banner"
          className="my-10 hidden max-w-md object-contain pl-5 md:flex"
        />

        <img
          src='https://cdn.mercadodeflores.pe/ingresa-o-registrate-mobile.jpg'
          alt="Banner"
          className="flex max-w-md object-contain md:hidden"
        />
      </div>
      <div className="flex w-full flex-col justify-center gap-10 px-5 md:w-1/2 md:py-5">
        <div className="flex w-full flex-col gap-5">
          <div className="form-control w-full flex-col items-center">
            <h3 className="text-xl font-bold">Ingresa o Regístrate</h3>
            <span className="text-center">
              Si aún no estás registrado la plataforma te guiará para crear tu cuenta gratis.
            </span>
          </div>
          <div className="form-control">
            <label className="label font-bold" htmlFor="celular_login">
              <span className="label-text">Ingresa tu correo</span>
            </label>
            <input
              autoComplete="off"
              name="celular_login"
              id="celular_login"
              type="text"
              maxLength={100}
              placeholder={placeholder}
              value={email}
              onChange={event => {
                const input = event.target.value
                setEmail(input.replace(" ", ""))
              }}
              className="input input-bordered w-full"
            />
          </div>
        </div>
        <button
          disabled={email.length < 3}
          onClick={() => LoginAccount()}
          className="btn rounded-full bg-green-700 py-3 text-base font-bold normal-case text-white"
        >
          Enviar código de verificación
        </button>
      </div>
    </div>
  ) : (
    <div className="relative flex w-full flex-col gap-0 pb-12 pt-4 md:flex-row md:gap-7 md:pb-0 md:pt-0">
      {isLoading && <Loading />}
      <div className="flex h-full flex-col items-center justify-center md:w-1/2 md:bg-[#F6FFF2]">
        <img
          src='https://cdn.mercadodeflores.pe/ingresa-o-registrate.jpg'
          alt="Banner"
          className="my-10 hidden max-w-md object-contain md:flex"
        />

        <img
          src='https://cdn.mercadodeflores.pe/ingresa-o-registrate-mobile.jpg'
          alt="Banner"
          className="flex max-w-md object-contain md:hidden"
        />
      </div>
      <div className="flex w-full flex-col justify-center gap-10 px-5 md:w-1/2 md:py-5">
        <div className="flex w-full flex-col gap-5 ">
          <div className="form-control w-full flex-col items-center gap-5">
            <h3 className="text-xl font-bold">Verifica tu cuenta</h3>
            <span>
              Ingresa el código de 6 dígitos que te enviamos al correo:{' '}
              <span className="font-bold text-green-700">
                {email}
              </span>
            </span>
          </div>
          <div className="form-control">
            <label className="label font-bold" htmlFor="verificacion_login">
              <span className="label-text">Código de 6 dígitos</span>
            </label>
            <input
              id="verificacion_login"
              name="verificacion_login"
              type="text"
              pattern="[0-9]{6}"
              maxLength={6}
              placeholder="Ingrese el código de 6 dígitos"
              value={code}
              onChange={event => {
                const input = event.target.value
                const regex = /^[0-9\b]+$/ // Expresión regular para solo aceptar números

                if (input === '' || regex.test(input)) {
                  setCode(input)
                }
              }}
              className="input input-bordered w-full"
            />
          </div>
        </div>
        <button
          disabled={code.length < 6}
          onClick={() => Confirm()}
          className=" rounded-full bg-green-700 py-3 text-base font-bold text-white"
        >
          Confirmar
        </button>

        <div className="flex w-full flex-col gap-2">
          <span className="text-sm text-gray-500">
            ¿No te llega el código de verificación?
          </span>
          <ul>
            <li className="text-sm text-gray-500">
              1. Revisa que el correo sea el correcto.
            </li>
            <li className="text-sm text-gray-500">
              2. Revisa que si el correo está en SPAM.
            </li>
            <li className="text-sm text-gray-500">
              3. Solicita la ayuda de un asesor{' '}
              <a
                href="https://wa.link/flores"
                title="Solicitar ayuda de un asesor"
                className="cursor-pointer font-bold underline"
                target="_blank"
              >
                aquí.
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Login
