import { initialState } from './initialState'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        user: { ...action.payload },
        isAuthenticated: true
      }
    case 'isAuthenticated':
      return {
        ...state,
        isAuthenticated: action.payload
      }
    case 'market':
      return {
        ...state,
        market: action.payload
      }
    case 'filter':
      return {
        ...state,
        filter: action.payload
      }
    case 'search':
      return {
        ...state,
        search: action.payload
      }
    case 'drawerCart':
      return {
        ...state,
        drawerCart: action.payload
      }
    case 'modal':
      return {
        ...state,
        modal: action.payload
      }
    case 'modalAuthType':
      return {
        ...state,
        modalAuthType: action.payload
      }
    case 'menuProfileStep':
      return {
        ...state,
        menuProfileStep: action.payload
      }
    case 'idQueryOrder':
      return {
        ...state,
        idQueryOrder: action.payload
      }
    case 'logout':
      return {
        ...initialState,
        isAuthenticated: false
      }
    default:
      return state
  }
}
