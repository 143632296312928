import React from 'react'
import ModalAuth from './ModalAuth'

function GlobalModalLayout({ children }) {
  // importar todos los modales que seran globales aqui

  return (
    <>
      <ModalAuth />
      {children}
    </>
  )
}

export default GlobalModalLayout
