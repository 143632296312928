import React, { useContext } from 'react'
import Login from '@components/container/Login'
import Modal from '@components/container/Modal'
import Register from '@components/container/Register'
import Verification from '@components/container/Verification'
import { UserDataContext } from '@context/userContext'

const ModalAuth = () => {
  const userData = useContext(UserDataContext)
  const {
    modal: { modalOpen },
    setModal,
    modalAuthType
  } = userData

  const handleCloseModal = () => {
    setModal(!modalOpen)
  }

  return (
    <Modal isOpen={modalOpen} closeModal={handleCloseModal}>
      {modalAuthType === 'login' && <Login />}
      {modalAuthType === 'register' && <Register />}
      {modalAuthType === 'verification' && <Verification />}
    </Modal>
  )
}

export default ModalAuth
