export const initialState = {
  product: {
    id: '',
    id_tienda: '',
    id_market: '',
    id_categoria: '',
    nombre: '',
    cantidad: 1,
    precio: '',
    url_imagen: '',
    lon: '',
    lat: '',
    is_van: false,
    is_exclusive: false
  },
  productsContext: [],
  products: [],
  additionals: {
    adicionales: [],
    adicionalesEscogidos: [],
    adicionalesTotalPrecio: 0,
    addAdditional: false
  },
  emitter: {
    nombres: '',
    telefono: '',
    correo: '',
    envio: 'No, lo recibe otro',
    anonimo: 0,
    recibo: true,
    guardadoEmitter: false
  },
  receiver: {
    nombresReceiver: '',
    telefonoReceiver: '',
    distrito: '',
    direccion: '',
    referencia: '',
    latitud: '',
    longitud: '',
    kilometros: '',
    guardadoReceiver: false,
    disableReceiver: true
  },
  dedication: {
    mensaje: [],
    frase: [],
    guardadoDedication: false,
    disableDedication: true
  },
  deliveryTime: {
    selectedDelivery: null,
    id_delivery: '',
    tipoDelivery: '',
    horario: '',
    fecha: '',
    guardadoDeliveryTime: false,
    disableDelivery: true
  },
  payment: {
    singleProduct: false,
    tipoPago: '',
    captura: null,
    precioRegular: 0,
    precioExpress: 0,
    precioEscogido: 0,
    total_precio: 0,
    subtotal: 0,
    comision: 0,
    igv: 0,
    total_impuestos: 0,
    total_precio_tarjeta: 0,
    disablePayment: true,
    cupon: {
      id: '',
      monto_descuento: 0,
      codigo_cupon: ''
    }
  }
}
