import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { UserDataProvider } from '@context/userContext'
import { BuyDataProvider } from '@context/buyContext'
import { ShopDataProvider } from '@context/shopContext'

import GlobalModalLayout from '@components/layouts/GlobalModalLayout'
import { Toaster } from 'react-hot-toast'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true
    }
  }
})
const Index = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserDataProvider>
        <BuyDataProvider>
          <ShopDataProvider>
            <GlobalModalLayout>
              {children}
              <Toaster
                position="bottom-right"
                toastOptions={{
                  duration: 4000
                }}
              />
              <ReactQueryDevtools initialIsOpen={false} panelPosition="bottom" />
            </GlobalModalLayout>
          </ShopDataProvider>
        </BuyDataProvider>
      </UserDataProvider>
    </QueryClientProvider>
  )
}

export default Index
