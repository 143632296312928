import React from 'react'
import './src/styles/global.css'
import App from '@App'

export const wrapPageElement = ({ element }) => {
  return <App>{element}</App>
}

export function shouldUpdateScroll() {
  window.scrollTo(0, 0)
  const body = document.getElementsByTagName('body')[0]
  body.scrollTop = 0
  return false
}
