export const initialState = {
  shop: {
    id_shop: '',
    shop_name: '',
    direction: '',
    lat: '',
    lon: '',
    url: '',
    image: '',
    products: []
  },
  product: {
    id_product: '',
    description: '',
    price: '',
    image: null,
    globe: 0,
    category: null
  },
  delivery: {
    district: '',
    direction: '',
    reference: '',
    latitude: '',
    longitude: '',
    km: '',
    price: ''
  },
  history: [
    {
      id_delivery: '',
      district: '',
      direction: '',
      reference: '',
      latitude: '',
      longitude: '',
      date: '',
      time: '',
      status: '',
      products: []
    }
  ]
}
