import { initialState } from './initialState'

export const shopReducer = (state, action) => {
  switch (action.type) {
    case 'shop':
      return {
        ...state,
        shop: { ...state.shop, ...action.payload }
      }
    case 'product':
      return {
        ...state,
        product: { ...state.product, ...action.payload }
      }
    case 'delivery':
      return {
        ...state,
        delivery: { ...state.delivery, ...action.payload }
      }
    case 'history':
      return {
        ...state,
        deliverys: [{ ...state.deliverys, ...action.payload }]
      }
    case 'reset':
      return {
        ...initialState
      }
    default:
      return state
  }
}
